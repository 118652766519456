import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  links = [
    { url: '', link: 'DASHBOARD.HOME', icon: 'fa-house' },
    /* { url: '/events', link: 'DASHBOARD.SHOWS', icon: 'fa-medal' }, */
    { url: '/testkit/activate', link: 'DASHBOARD.TESTKIT', icon: 'fa-box' },
    { url: '/my-pets', link: 'DASHBOARD.MY_DOGS', icon: 'fa-dog' },
  ];

  constructor() { }

}
