import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './modules/layout/layout.component';
import { UnauthorizedLayoutComponent } from './modules/layout/unauthorized-layout/unauthorized-layout.component';
import { AuthGuard } from './resources/auth.guard';
import { LoginGuard } from './resources/login.guard';
import { AdminGuard } from './resources/admin.guard';
import { AnimalProfileComponent } from './modules/animal-profile/animal-profile.component';
import { SharedProfileLayoutComponent } from './modules/layout/shared-profile-layout/shared-profile-layout.component';
import { EventGuard } from './resources/event.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        // canActivate: [AdminGuard],
      },
      {
        path: 'animal-details',
        loadChildren: () => import('./modules/animal-details/animal-details.module').then(m => m.AnimalDetailsModule),
      },
      {
        path: 'animal',
        loadChildren: () => import('./modules/animal-profile/animal-profile.module').then(m => m.AnimalProfileModule),
      },

      {
        path: 'matching',
        loadChildren: () => import('./modules/animal-matching/animal-matching.module').then(m => m.AnimalMatchingModule),
        // canActivate: [AdminGuard],
      },
      {
        path: 'animal-search',
        loadChildren: () => import('./modules/animal-search/animal-search.module').then(m => m.AnimalSearchModule),
        // canActivate: [AdminGuard],
      },
      {
        path: 'testkit',
        loadChildren: () => import('./modules/testkit/testkit.module').then(m => m.TestkitModule),
        // canActivate: [AdminGuard],
      },
      {
        path: 'account',
        loadChildren: () => import('./modules/account-settings/account-settings.module').then(m => m.AccountSettingsModule),
        // canActivate: [AdminGuard],
      },
      {
        path: 'events',
        loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule),
        canActivate: [EventGuard],
      },
    ]
  },
  {
    path: 'login',
    component: UnauthorizedLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        canActivate: [LoginGuard]
      },
    ]
  },
  {
    path: 'qr_code_pdf_report',
    component: UnauthorizedLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/qr-code/qr-code.module').then(m => m.QrCodeModule),
      }
    ]
  },
  {
    path: 's',
    component: SharedProfileLayoutComponent,
    children: [
      {
        path: '',
        // loadChildren: () => import('./modules/animal-profile/animal-profile.module').then(m => m.AnimalProfileModule),
        loadChildren: () => import('./modules/shared-animal-profile/shared-animal-profile.module').then(m => m.SharedAnimalProfileModule),
      }

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
