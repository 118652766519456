import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BackendData, Info } from '../models/http-response.model';
import { AnimalsPageViewType, Details, KeyMessage, User, UserDetails } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _currentUser: BehaviorSubject<any> = new BehaviorSubject<User | null>(null);
  private _gridViewType: `${AnimalsPageViewType}` = AnimalsPageViewType.grid;

  constructor(private http: HttpClient) {}

  get currentUser$(): Observable<User> {
    return this._currentUser.asObservable();
  }

  getUserRole(): string {
    return this._currentUser?.value?.data?.type;
  }

  setCurrentUser(user: User | null): void {
    this._currentUser.next(user);
    this._gridViewType = user ? user.data.animalsPageViewType : null;
  }

  getCurrentViewType(): `${AnimalsPageViewType}` {
    return this._gridViewType;
  }

  setCurrentViewType(viewType: `${AnimalsPageViewType}`): void {
    this._gridViewType = viewType;
  }


  getCurrentUser(): Observable<User> {
    return this.http
      .get<User>(`${environment.users}/self`)
      .pipe(tap((user: User) => this.setCurrentUser(user)));
  }

  updateUser(user: User): Observable<User> {
    return this.http.put<User>(environment.users, user);
  }

  changeUserLanguage(language: string): Observable<Info> {
    return this.http.patch<Info>(environment.change_language, {language});
  }

  changeAnimalsPageViewType(animalsPageViewType: string): Observable<Info> {
    return this.http.patch<Info>(environment.change_animals_page_view_type, {animalsPageViewType});
  }

  changePassword(payload: any): Observable<KeyMessage> {
    return this.http.post<KeyMessage>(environment.changePassword, payload);
  }

  updateProfile(payload: any): Observable<KeyMessage> {
    return this.http.post<KeyMessage>(environment.updateProfile, payload);
  }

  deleteProfile(): Observable<any>{
    return this.http.delete<KeyMessage>(environment.users);
  }

  contactInfo(payload: any): Observable<BackendData<UserDetails>> {
    return this.http.post<BackendData<UserDetails>>(environment.userDetails, payload);
  }

  createUser(user: any): Observable<User> {
    return this.http.post<User>(environment.users, user);
  }

  userDetails(id: string): Observable<Details> {
    return this.http.get<BackendData<Details>>(`${environment.userDetails}/${id}`).pipe(
      pluck('data')
    );
  }

  getUserHideBannersProperty(): boolean {
    return this._currentUser?.value?.data?.hideBanners;
  }

  changeUserHideBannersProperty(hideBanners: boolean): Observable<Info> {
    return this.http.patch<Info>(environment.change_banner_visibility, {hideBanners});
  }
}
