import { Locations } from '../resources/locations';

export interface User {
  timestamp: number;
  data: {
    uuid: string;
    email: string;
    firstName: string;
    lastName: string;
    type: AccountType;
    userDetails: UserDetails;
    language: string;
    animalsPageViewType: `${AnimalsPageViewType}`;
    hideBanners: boolean;
  };
}

export enum AccountType {
  Breeder = 'breeder',
  Veterinary = 'veterinary',
  Owner = 'owner',
  Admin = 'admin',
  BreedingClub = 'breeding_club',
  Moderator = 'moderator'
}

export interface UserDetails {
      uuid: string;
      contactEmail: string;
      phone: string;
      country: string | Locations;
      address: string;
      city: string;
      province: string;
      postalCode: string;
      public: boolean;
}

export interface KeyMessage {
  message: string;
}

export interface AccountTypes {
  type: AccountType;
  output: string;
}

export interface Details {
  firstName: string;
  lastName: string;
  type: string;
  userDetails: UserDetails;
}

export enum AnimalsPageViewType {
  grid = 'grid',
  list = 'list'
}
